<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Postulaciones Nacionales</h5>
              </div>

              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Operaciones</li>
                  <li class="breadcrumb-item active">
                    Postulaciones Nacionales
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header p-0 pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#tep_postulacion_nacionales_export"
                        v-if="
                          $store.getters.can(
                            'tep.nacionalesPostulaciones.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <!-- SE LLAMAN BOTONES ACA -->
                      <div class="col-md-2">
                        <div class="btn-group float-right"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body table-responsive p-0">
                <table
                  id="users"
                  class="table table-bordered table-striped table-hover table-sm"
                  style="font-size: 11px;"
                >
                  <thead>
                    <tr>
                      <th>
                        # De Servicio
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.tep_viaje_id"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Usuario<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.user"
                          @change="getIndex()"
                        />
                      </th>
                      <th class="text-center">Pasajero</th>
                      <th>
                        Origen<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.origen"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Destino<input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.destino"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        Transportadora
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="filtros.empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Inicio Servicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fin Servicio
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Tipo Vehículo
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_vehiculo"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="tipos_vehiculos in listasForms.tipos_vehiculos"
                            :key="tipos_vehiculos.id"
                            :value="tipos_vehiculos.id"
                          >
                            {{ tipos_vehiculos.nombre }}
                          </option>
                        </select>
                      </th>
                      <th>
                        Vehículo
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.vehiculo"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Conductor
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.conductor"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.id"
                            :value="estado.numeracion"
                          >
                            {{
                              estado.descripcion == "CREADO"
                                ? "PENDIENTE"
                                : estado.descripcion
                            }}
                          </option>
                        </select>
                      </th>
                      <th>servicios</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in viajes.data" :key="item.id">
                      <td class="text-center">{{ item.id }}</td>
                      <td class="text-nowrap">{{ item.user ? item.user.name : "" }}</td>
                      <td
                        class="text-center"
                        v
                        v-if="item.solicitudes.pasajeros.length > 0"
                      >
                        <div
                          v-for="pasajeros in item.solicitudes.pasajeros"
                          :key="pasajeros.id"
                        >
                          <span class="badge bg-navy">
                            {{ pasajeros.nombres }}
                          </span>
                        </div>
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge badge-warning"> Sin Asignar </span>
                      </td>
                      <td>{{ item.direccion_origen }}</td>
                      <td>{{ item.direccion_destino }}</td>
                      <td class="text-nowrap">{{ item.transportadora.razon_social }}</td>
                      <td>{{ item.fecha_ini }}</td>
                      <td>{{ item.fecha_fin }}</td>
                      <td>{{ item.tipo_vehiculo.nombre }}</td>
                      <td>{{ item.vehiculo ? item.vehiculo.placa : "sin asignar" }}</td>
                      <td>
                        {{
                          item.conductor
                            ? item.conductor.nombres +
                              " " +
                              item.conductor.apellidos
                            : "sin asignar"
                        }}
                        <span class="badge badge-info" v-if="item.conductor">
                          {{ item.conductor.numero_documento }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          :class="
                            item.estado == 1
                              ? 'badge-warning'
                              : item.estado == 2
                              ? 'badge-info'
                              : item.estado == 3
                              ? 'badge-success'
                              : item.estado == 4
                              ? 'badge-primary'
                              : item.estado == 5
                              ? 'bg-navy'
                              : item.estado == 6
                              ? 'badge-danger'
                              : item.estado == 7
                              ? 'bg-teal'
                              : item.estado == 8
                              ? 'badge-danger'
                              : ''
                          "
                        >
                          {{
                            item.nEstado == "CREADO"
                              ? "PENDIENTE"
                              : item.nEstado
                          }}
                        </span>
                      </td>
                      <td style="width: 50px" class="text-right">
                        <div class="btn-group">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            data-toggle="modal"
                            data-target="#modal-form-asignacion-tep-postulacion-nacional"
                            style="cursor: pointer"
                            v-if="$store.getters.can('tep.nacionalesPostulaciones.edit')"
                            @click="llenarModal(item)"
                            :disabled="compararFecha(item.fecha_ini)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="viajes.total">
                  <p>
                    Mostrando del <b>{{ viajes.from }}</b> al
                    <b>{{ viajes.to }}</b> de un total:
                    <b>{{ viajes.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="viajes"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PostulacionNacionalAsignacion ref="PostulacionNacionalAsignacion" />
    <PostulacionNacionalExport ref="PostulacionNacionalExport" />
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import PostulacionNacionalAsignacion from "./PostulacionNacionalAsignacion";
import PostulacionNacionalExport from "./PostulacionNacionalExport";
import vSelect from "vue-select";
import moment from "moment";
export default {
  name: "PostulacionNacionalIndex",
  components: {
    Loading,
    pagination,
    PostulacionNacionalAsignacion,
    PostulacionNacionalExport,
    vSelect,
  },
  data() {
    return {
      cargando: false,
      viajes: {},
      hoy: moment().format("YYYY-MM-DD"),
      empresas: [],
      filtros: {
        id_solicitud: null,
        origen: null,
        destino: null,
        tipo_vehiculo: null,
        fecha_ini: null,
        fecha_fin: null,
        transportadora: null,
        empresa: null,
      },
      listasForms: {
        tipos_vehiculos: [],
        estados: [],
      },
    };
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      if (this.filtros.empresa !== null) {
        this.filtros.transportadora_id = this.filtros.empresa.id;
      } else {
        this.filtros.transportadora_id = null;
      }

      axios
        .get("/api/tep/PostulacionNacional?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.viajes = response.data;
          this.cargando = false;
        });
    },

    getEstados() {
      axios.get("/api/lista/109").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    /*     async getTiposVehiculos() {
      await axios.get("/api/admin/tiposVehiculos/lista").then((response) => {
        this.listasForms.tipos_vehiculos = response.data;
      });
    }, */

    async getTiposVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getMsg() {
      if (this.$route.params.msg) {
        this.$swal({
          icon: "success",
          title: this.$route.params.msg,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
      }
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: {
              linea_negocio_id: [5],
            },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.transportadora_id = this.transportadora.id;
        this.getIndex();
      } else {
        this.filtros.transportadora_id = null;
        this.getIndex();
      }
    },

    llenarModal(item) {
      if (this.$store.getters.can("tep.nacionalesPostulaciones.edit")) {
        this.$refs.PostulacionNacionalAsignacion.llenar_modal(item);
      }
    },

    compararFecha(fecha) {
      return false;
      /* let fecha_ini = moment(fecha).format("YYYY-MM-DD");
      let hoy = moment().format("YYYY-MM-DD");

      if (fecha_ini < hoy) {
        return true;
      } else {
        return false;
      } */
    },
  },
  mounted() {
    this.getIndex();
    this.getMsg();
    this.getTiposVehiculos();
    this.getEstados();
  },
};
</script>
